// export const BASE_URL = "https://pay.zkeduo.com/";
// export const BASE_URL = "http://127.0.0.1:8000/";
// export const BASE_URL = "https://pay-staging.zkeduo.com/";
export const BASE_URL = "https://test.zkeduo.com/";

//  export const BASE_URL = "http://testww.zkeduo.com/"
// websocket
export const WSS_URL = "wss://test.zkeduo.com/wss/";
// export const WSS_URL = "wss://pay-staging.zkeduo.com/wss/";
// export const WSS_URL = "wss://pay.zkeduo.com/wss/";
