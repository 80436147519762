
import accessPay from "./accessPay"
import rate from "./rate"
import merchantTest from "./merchantTest"

const ums = {
    path: 'ums/:merchantId',
    component: resolve => require(['@/pages/admin/reviewed/ums'], resolve),
    redirect: 'ums/:merchantId/accessPay',
    children: [
        accessPay,
        rate,
        merchantTest,
    ]
}

export default ums