import homeIndex from "./home"
import accessPay from "./accessPay"
import rate from "./rate"
import merchantTest from "./merchantTest"
const ums = {
    path: 'ums',
    component: resolve => require(['@/pages/admin/merchant/signing/ums'], resolve),
    redirect: 'ums/accessPay',
    children: [
        homeIndex,
        accessPay,
        rate,
        merchantTest,
    ]
}

export default ums