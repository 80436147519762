import auditList from "./audit_list";
import recharge from "./recharge";
import partner_list from "./partner_list";
import orderList from "./order_list";
import agent_bill_export from "./agent_bill_export"

const storageAudit = {
  path: "/storage_audit",
  component: (resolve) => require(["@/pages/admin/storage_audit"], resolve),
  redirect: "storage_audit/audit_list",
  children: [auditList, recharge, partner_list, orderList,agent_bill_export],
  meta: {
    requireAuth: true,
  },
};

export default storageAudit;
